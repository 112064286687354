<template>
  <admin>
    <page-header>
      <template v-slot:title>
        <span>CS Online CDC Summary</span>
      </template>
      <template v-slot:action>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          large
          color="grey"
          class="white--text"
          exact
          @click="download"
          :loading="downloadLoading"
          :disabled="downloadLoading"
        >
          <v-icon small left>mdi-cloud-download</v-icon>
          <span v-text="'Download'"></span>
        </v-btn>
      </template>
    </page-header>
    
    <div class="pt-4">
      <div class="mb-4">
        <div v-if="cdcCountsLoading">
          <v-row>
            <v-col v-for="index in 3" :key="index" cols="2">
              <v-skeleton-loader
                height="100"
                max-width="300"
                type="image"
                class="pr-2"
              />
            </v-col>
          </v-row>
        </div>
        <div v-else>
           <v-row>
              <template v-for="(factor, key) in cdcCounts">
                <v-col
                  :key="`factor-${key}`"
                  :cols="cdcCounts.length <= 2 ? 2 : ''"
                  class="pt-0 mb-2"
                >
                  <v-card
                    class="flex-column justify-space-around"
                    style="width: 100%; height: 100%"
                  >
                    <v-card-text align="center" class="pt-3 pb-0">
                      <div class="text-h4 font-weight-black mb-0">
                        {{ factor.count }}
                      </div>
                    </v-card-text>
                    <v-card-title class="pb-2 pt-0 px-2">
                      <div
                        class="text-center body-2 my-2"
                        style="line-height: 1; width: 100%"
                      >
                        {{ factor.title }}
                      </div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </template>
            </v-row>
        </div>
      </div>
    </div>

    <v-card>
      <can :code="['admin', 'admin_survey']">
        <survey-filter
          ref="toolbar"
          :filterDateRange="true"
          :advanceFilter="true"
          :filterRegions="true"
          :filterGroups="true"
          :filterZones="true"
          :filterDealer="true"
          :isSearchColsAuto="true"
          :items.sync="tabletoolbar"
          @update:search="setSearch"
          @update:daterangesurvey="setDateRange"
          @update:regions="setRegions"
          @update:zones="setZones"
          @update:groups="setGroups"
          @update:dealer="setDealer"
        />
      </can>

      <can :code="['dealer_survey', 'dealer']">
        <survey-filter
          ref="toolbar"
          :showAdvanceFilterBtn="false"
          :filterDateRange="true"
          :advanceFilter="false"
          :filterRegions="false"
          :filterGroups="false"
          :filterZones="false"
          :filterDealer="false"
          :isSearchColsAuto="true"
          :items.sync="tabletoolbar"
          @update:search="setSearch"
          @update:daterangesurvey="setDateRange"
          @update:regions="setRegions"
          @update:zones="setZones"
          @update:groups="setGroups"
          @update:dealer="setDealer"
        />
      </can>

      <v-data-table
        :loading="resources.loading"
        :headers="
          this.$route.params.id ? resources.headers : resources.headersDefault
        "
        :items="items"
        :items-per-page="5"
        :server-items-length="resources.total"
        :options.sync="resources.options"
        class="text-no-wrap"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            text
            color="primary"
            icon
            v-on="on"
            @click="showSurveyResult(item.id)"
          >
            {{ item.id }}
          </v-btn>
        </template>

        <template v-slot:item.id="{ item }">
          <div class="text-no-wrap">
            <v-btn @click="showSurveyResult(item.id)" text color="primary" icon>
              {{ item.id }}
            </v-btn>
          </div>
        </template>

        <template v-slot:item.customer_fullname="{ item }">
          {{ item.fullname }}<br />
          <small>{{ item.mobile }}</small> <br />
          <small>{{ item.email }}</small>
        </template>

        <template v-slot:item.survey_name="{ item }">
          {{ item.survey_name }}
        </template>

        <template v-slot:item.deadline_at="{ item }">
          <v-icon
            v-if="item.deadline_at"
            :color="getDeadlineColor(item.days_before_deadline_count)"
          >
            {{ getDeadlineIcon(item.days_before_deadline_count) }} small
          </v-icon>
          &nbsp;
          <small
            v-if="item.deadline_at"
            :class="
              getDeadlineColor(item.days_before_deadline_count) + '--text'
            "
          >
            {{ formatDate(item.deadline_at) }}
          </small>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip label>
            {{ item.status.code }}
          </v-chip>
        </template>

        <template v-slot:item.created_at="{ item }">
          <span>{{ formatDate(item.created_at) }}</span>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- View Details -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon v-on="on" @click="showSurveyResult(item.id)">
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span v-text="'View Details'"></span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <template v-if="previewDialog">
      <v-dialog
        v-model="previewDialog"
        max-width="900px"
        class="overflow-hidden"
      >
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Survey Result" class="mb-1">#{{ dialogItem.id }}</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <!-- Background Details -->
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Customer" }}
                  </td>
                  <td>
                    {{
                      `${dialogItem.title} ${
                        dialogItem.fullname ? dialogItem.fullname : ""
                      }`
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Mobile" }}
                  </td>
                  <td>
                    {{ dialogItem.mobile }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Email" }}
                  </td>
                  <td>
                    {{ dialogItem.email }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Model & Year" }}
                  </td>
                  <td>
                    {{ dialogItem.model + " " + dialogItem.year_model }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "CS Number / Plate Number" }}
                  </td>
                  <td>
                    {{ dialogItem.cs_plate_number }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Dealer" }}
                  </td>
                  <td>
                    {{ dialogItem.dealer_name }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <hr style="border-top: 1px solid var(--sidebar-border)" />
            <br />
            <v-simple-table>
              <tbody>
                <tr
                  v-for="(entry, name, index) in dialogItem.response_data"
                  :key="index"
                >
                  <td style="width: 50%" class="font-weight-bold">
                    <small>{{ entry.title }}</small>
                  </td>
                  <td
                    class="text-center"
                    v-if="Array.isArray(entry.value)"
                    style="width: 50%"
                  >
                    <v-chip v-for="v in entry.value" class="mr-1" :key="v">{{
                      v
                    }}</v-chip>
                  </td>
                  <td
                    class="text-center"
                    v-else-if="
                      typeof entry.value === 'object' &&
                      entry.value !== null &&
                      !Array.isArray(entry.value)
                    "
                    style="width: 50%"
                  >
                    <small
                      v-for="(v1, n, i) in entry.value"
                      class="d-block mr-1"
                      :key="i"
                      ><strong>{{ n }}</strong> - {{ v1 }}</small
                    >
                  </td>
                  <td class="text-center" v-else style="width: 50%">
                    {{ entry.value }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <!-- Background Details -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              color="grey"
              exact
              class="ma-1 white--text px-5"
              @click="previewDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </admin>
</template>
<script>
import * as helpers from "@/core/helpers";
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash";
import apiModuleType from "@/services/api/modules/moduleTypeService";

export default {
  data() {
    return {
      tabletoolbar: {
        isSearching: false,
        search: null,
        toggleBulkEdit: false,
        bulkCount: 0,
        actionDropdownItems: [
          {
            slug: "activate",
            action: "Activate",
          },
          {
            slug: "deactivate",
            action: "Deactivate",
          },
          {
            slug: "delete",
            action: "Delete",
          },
        ],
      },
      resources: {
        headers: [
          {
            text: "ID",
            align: "center",
            value: "id",
          },
          {
            text: "Customer Information",
            align: "left",
            value: "customer_fullname",
          },
          {
            text: "Deadline",
            align: "left",
            value: "deadline_at",
          },
          {
            text: "Date Created",
            align: "left",
            value: "created_at",
          },
          {
            text: "Action",
            align: "left",
            value: "action",
          },
        ],
        headersDefault: [
          {
            text: "ID",
            align: "center",
            value: "id",
          },
          {
            text: "Customer Information",
            align: "left",
            value: "customer_fullname",
          },
          {
            text: "Deadline",
            align: "left",
            value: "deadline_at",
          },
          {
            text: "Status",
            align: "left",
            value: "status",
          },
          {
            text: "Date Created",
            align: "left",
            value: "created_at",
          },
          {
            text: "Action",
            align: "left",
            value: "action",
            sortable: false,
          },
        ],
        total: 5,
        loading: true,
        options: {},
      },
      items: [],
      options: {},
      previewDialog: false,
      editDialog: false,
      dialogItem: {},
      resendLoading: false,
      downloadLoading: false,
      title: "",
      filters: {
        q: "",
        date: [],
        regions: "",
        zones: "",
        groups: "",
        dealers: "",
      },
      selectedResult: "",
      statuses: [],
      cdcCounts: [],
      cdcCountsLoading: false,
    };
  },
  watch: {
    "resources.options": {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
        const orderBy = sortBy.length ? sortBy[0] : "created_at";
        const sortDir = !sortDesc[0] ? "DESC" : "ASC";

        this.getSurveyResults(page, itemsPerPage, orderBy, sortDir);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      results: "survey/GET_SURVEY_CDCS",
      result: "survey/GET_SURVEY_CDC",
      immediateMeasures: "cdc/GET_IMMEDIATE_MEASURES",
      factors: "cdc/GET_FACTORS",
      survey: "survey/GET_SURVEY",
    }),
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/show",
      getCSCdcs: "survey/getCSCdcs",
      findSurveyCdc: "survey/findSurveyCdc",
      getDealerZones: "dealerZone/getDealerZones",
      getDealerGroups: "dealerGroup/getDealerGroups",
      getDealers: "dealer/getDealers",
      downloadCdc: "survey/downloadCSCdc",
      getFactors: "cdc/getFactors",
      getFactorsCount: "cdc/getCSFactorsCount",
      findSurvey: "survey/find",
    }),
    getDeadlineIcon(days) {
      if (days > 3) {
        return "mdi-clock";
      }

      if (days >= 1) {
        return "mdi-clock-time-ten";
      }

      return "mdi-clock-alert";
    },
    getDeadlineColor(days) {
      if (days > 3) {
        return "green";
      }

      if (days >= 1) {
        return "orange";
      }

      return "red";
    },
    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      const orderBy = sortBy.length ? sortBy[0] : "created_at";
      const sortDir = !sortDesc[0] ? "DESC" : "ASC";

      this.filters.q = e.target.value;

      this.getSurveyResults(1, itemsPerPage, orderBy, sortDir);
    }, 300),
    async download() {
      let self = this;
      self.downloadLoading = true;

      await this.downloadCdc({
        q: this.filters.q,
        date: this.filters.date,
        regions: this.filters.regions ? [this.filters.regions] : [],
        zones: this.filters.zones ? [this.filters.zones] : [],
        groups: this.filters.groups ? [this.filters.groups] : [],
        dealers: this.filters.dealers ? [this.filters.dealers] : [],
      }).then(function () {
        self.downloadLoading = false;
      });
    },
    async refreshResults(page, itemsPerPage) {
      this.getSurveyResults(1, itemsPerPage);
      await this.getFactorsCount2({});
    },
    async getSurveyResults(
      page,
      itemsPerPage,
      orderBy = "created_at",
      sortDir = "DESC"
    ) {
      const self = this;

      self.resources.loading = true;

      await self
        .getCSCdcs({
          page: page,
          per_page: itemsPerPage,
          q: this.filters.q,
          date: this.filters.date,
          regions: this.filters.regions ? [this.filters.regions] : [],
          zones: this.filters.zones ? [this.filters.zones] : [],
          groups: this.filters.groups ? [this.filters.groups] : [],
          dealers: this.filters.dealers ? [this.filters.dealers] : [],
          order_by: orderBy,
          sort: sortDir,
        })
        .then(function () {
          self.resources.loading = false;
          self.items = self.results.data;
          self.tabletoolbar.isSearching = false;
          self.resources.total =
            itemsPerPage > 0
              ? self.results.meta.total
              : self.results.data.length;
        });
    },
    formatDate(date) {
      return helpers.format_date(date);
    },
    showSurveyResult(id) {
      const self = this;
      self.findSurveyCdc(id).then(function () {
        self.dialogItem = self.result;
        self.previewDialog = true;
      });
    },
    showSelectedResult(result, viewDetails = false) {
      if (viewDetails) {
        this.previewDialog = false;
      }

      this.selectedResult = result;
      this.editDialog = true;
    },
    setDateRange(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.filters.date = e;
      const orderBy = sortBy.length ? sortBy[0] : "created_at";
      const sortDir = !sortDesc[0] ? "DESC" : "ASC";

      this.getSurveyResults(1, itemsPerPage);
    },
    async setRegions(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      const orderBy = sortBy.length ? sortBy[0] : "created_at";
      const sortDir = !sortDesc[0] ? "DESC" : "ASC";
      this.filters.regions = e;

      //Set zone filter
      let regions = this.filters.regions ? [this.filters.regions] : [];
      await this.getDealerZones(regions);

      //Set groups filter
      this.filters.zones = "";
      await this.getDealerGroups({
        region_types: this.filters.regions ? [this.filters.regions] : [],
        zones: this.filters.zones ? [this.filters.zones] : [],
      });

      //Set dealer filter
      this.filters.groups = "";
      await this.getDealers({
        region_types: this.filters.regions ? [this.filters.regions] : [],
        zones: this.filters.zones ? [this.filters.zones] : [],
        groups: this.filters.groups ? [this.filters.groups] : [],
      });

      //Get results
      await this.getSurveyResults(1, itemsPerPage, orderBy, sortDir);
    },
    async setZones(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      const orderBy = sortBy.length ? sortBy[0] : "created_at";
      const sortDir = !sortDesc[0] ? "DESC" : "ASC";
      console.log('e', e);

      this.filters.zones = e ? e.value : null;

      //Set groups filter
      await this.getDealerGroups({
        region_types: this.filters.regions ? [this.filters.regions] : [],
        zones: this.filters.zones ? [this.filters.zones] : [],
      });

      //Set dealer filter
      this.filters.groups = "";
      this.filters.dealers = "";

      await this.getDealers({
        region_types: this.filters.regions ? [this.filters.regions] : [],
        zones: this.filters.zones ? [this.filters.zones] : [],
        groups: this.filters.groups ? [this.filters.groups] : [],
      });

      // Get results
      await this.getSurveyResults(1, itemsPerPage, orderBy, sortDir);
    },
    async setGroups(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      const orderBy = sortBy.length ? sortBy[0] : "created_at";
      const sortDir = !sortDesc[0] ? "DESC" : "ASC";
      this.filters.groups = e ? e.value : null;

      //Set dealer filter
      this.filters.dealers = "";

      await this.getDealers({
        region_types: this.filters.regions ? [this.filters.regions] : [],
        zones: this.filters.zones ? [this.filters.zones] : [],
        groups: this.filters.groups ? [this.filters.groups] : [],
      });

      // Get results
      await this.getSurveyResults(1, itemsPerPage, orderBy, sortDir);
    },
    async setDealer(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      const orderBy = sortBy.length ? sortBy[0] : "created_at";
      const sortDir = !sortDesc[0] ? "DESC" : "ASC";
      this.filters.dealers = e;

      // Get results
      await this.getSurveyResults(1, itemsPerPage, orderBy, sortDir);
      await this.getFactorsCount2({ dealer_id: e });
    },
    async getStatuses() {
      const { status, data } = await apiModuleType.getStatusList("cdc");
      if (status === 200) {
        this.statuses = data.data;
      }
    },
    async getFactorsCount2(data = { dealer_id: null}) {
      await this.getFactorsCount({ sort: "ASC", ...data })
        .then((response) => {
          this.cdcCounts = response;
        })
        .catch(() => {
          this.cdcCounts = {};
        })
        .finally(() => {
          this.cdcCountsLoading = false;
        });
    },
  },
  async mounted() {
    this.cdcCountsLoading = true;
    const self = this;

    await this.getFactors();
    await this.getFactorsCount2({});
  },
};
</script>
